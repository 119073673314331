import React from 'react';
import { Helmet } from 'react-helmet-async';
import socialimgdefault from '../../assets/images/hqzsocial.jpeg';

const SEO = ({title, description, socialimg, robot}) => {
  return (
    <Helmet>
        <title>{title ? title : "HQZ | The Official Sports Streaming Network"}</title>
        <meta name="description" content={description ? description : "Exclusive sports shows, series, and interactive content on HQZ - the official sports streaming network. Available now on iOS and Android!"} />
        <meta name="robots" content="max-image-preview:large" />
        <meta property="og:title" content={title ? title : "HQZ | The Official Sports Streaming Network"} />
        <meta property="og:description" content={description ? description : "Exclusive sports shows, series, and interactive content on HQZ - the official sports streaming network. Available now on iOS and Android!"} />
        <meta property="og:url" content="https://hqz.gg/" />
        <meta property="og:image" content={socialimg ? socialimg : socialimgdefault} />
        <meta property="og:image:secure_url" content={socialimg ? socialimg : socialimgdefault} />
        <meta name="twitter:title" content={title ? title : "HQZ | The Official Sports Streaming Network"} />
        <meta name="twitter:description" content={description ? description : "Exclusive sports shows, series, and interactive content on HQZ - the official sports streaming network. Available now on iOS and Android!"} />
        <meta name="twitter:image" content={socialimg ? socialimg : socialimgdefault} />
        <meta name="robots" content={robot ? robot : "index,follow"} />
    </Helmet>
  );
};

export default SEO;