import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './landing/Home';
import Apple from './landing/Apple';
import Android from './landing/Android';
import About from './landing/About';
import Guidelines from './landing/Guidelines';
import Privacy from './landing/Privacy';
import Terms from './landing/Terms';
import Parents from './landing/Parents';
import Contact from './landing/Contact';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/apple" element={<Apple />} />
            <Route path="/android" element={<Android />} />
            <Route path="/about" element={<About />} />
            <Route path="/guidelines" element={<Guidelines />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/childrens-privacy" element={<Parents />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
