import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';
import { Link } from 'react-router-dom';

const Parents = () => {
  return (
    <>
      <SEO title="Children's Privacy Policy - HQZ" />
      <div className="position-relative bg-black">
        <Header />
        <div className="container py-5 text-white">
            <h1 className="mb-5">Children's Privacy Policy</h1>
            <p><em>Last updated: May 22, 2023</em></p>
            <p>HQZ Inc. ("HQZ", "we" or "us") is committed to protecting the privacy of children. This Children's Privacy Policy explains how we collect, use, share, and 
                otherwise process the personal information of users under the age of 13 (“Children”) on a separate experience of the HQZ services for Children in the United 
                States ("Kids Mode").</p>
            <p>Kids Mode is designed for Children residing in the United States. It allows Children to engage with HQZ's fun video features while limiting the information 
                collected from them. Children can view videos from other creators and explore their creativity by capturing their own videos with music and special effects. While 
                Children may save these videos directly to their device, the videos will not be saved by us or viewable by other users. Children also have a more limited interactive 
                experience, including, for example, they cannot exchange messages with other users, and other users cannot view their profiles.</p>
            
            <h2>What Information We Collect from Children</h2>
            <p>When a Child registers for HQZ, we collect only limited information, including username, password, and birthday.</p>
            <p>We may also collect certain information automatically from the Child's device, including internet or other network activity information such as device ID, IP 
                address, web browser type and version, country-level location, as well as certain app activity information, such as video watches, time in the app, and general
                usage information.</p>
            <p>If a Child reaches out to us via the in-app feedback form, we collect the email address that the Child provides solely for the purpose of responding to the inquiry.</p>
            
            <h2>How We Use Children's Information</h2>
            <p>We use the information we collect to provide and support our services. For example, we use username and password to authenticate Children. We may use the information 
                that is collected automatically to provide personalized content; serve contextual advertising; perform analytics and troubleshoot; protect the security and integrity 
                of the user and our services; and ensure legal and regulatory compliance.</p>
            <p>Children cannot publicly share personal information, including videos or profile information.</p>

            <h2>Data Security and Retention</h2>
            <p>We take steps to ensure that Children's information is treated securely and in accordance with this policy. Unfortunately, the transmission of information via the 
                internet is not completely secure. Although we will do our best to protect this information, for example, by encryption, we cannot guarantee the security of 
                information transmitted through the HQZ services; any transmission is at the user's own risk.</p>
            <p>We have appropriate technical and organizational measures to ensure a level of security appropriate to the risk of varying likelihood and severity for the rights 
                and freedoms of users. We maintain these technical and organizational measures and will amend them from time to time to improve the overall security of our systems.</p>
            <p>We retain Children's information for as long as reasonably necessary to fulfill the purpose for which the information was collected. The retention periods are 
                different depending on different criteria, such as the type of information and the purposes for which we use the information.</p>
            <p>HQZ may transmit Children's information to its servers or data centers outside of the United States for storage and/or processing. Other entities with whom HQZ may 
                share Children's information as described herein may be located outside of the United States.</p>

            <h2>Rights and Choices</h2>
            <p>A parent or guardian may submit a request to know, access, delete, or correct the information we have collected from their Child, as well as a request to appeal a 
                decision we have made about such request, by contacting us via the webform or physical address provided in the Contact Us section below. The right to know includes 
                what personal information we have collected about the Child, including the categories of sources from which the personal information is collected, the business 
                purpose for collecting or disclosing personal information, the categories of third parties to whom we share the personal information, and the specific pieces of 
                personal information we have collected about the Child.</p>
            <p>We will respond to requests consistent with applicable law and subject to proper verification. We will verify a parent or guardian's request by requesting 
                completion of HQZ’s Parental Access Form, along with limited additional information about the account, as appropriate and necessary for verification. We do not 
                discriminate based on the exercise of any privacy rights that a user, parent, or guardian might have.</p>
            
            <h2>Privacy Policy Updates</h2>
            <p>We may update this Privacy Policy from time to time. When we update the Privacy Policy, we will notify you by updating the “Last Updated” date at the top of the new
                Privacy Policy, posting the new Privacy Policy, or providing any other notice required by applicable law. We recommend that you review the Privacy Policy each time 
                you visit the Platform to stay informed of our privacy practices.</p>
            
            <h2>Contact Us</h2>
            <p>Questions, comments and requests regarding this Privacy Policy should be addressed to:</p>
            <ul>
                <li>
                    Parents or guardians may contact us: <Link to="/contact-us" className="text-white text-decoration-none">Contact</Link>
                </li>
                <li>
                    Mailing Address: HEADQTRZ LLC: 964 E Aquarius Pl. Chandler, AZ, 85249 USA
                </li>
            </ul>
          </div>
        </div>
      <Footer />
    </>
  );
};

export default Parents;