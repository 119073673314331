import React from 'react';
import Header from './common/Header';
import SEO from './common/SEO';
import logo from '../assets/images/hqz-iphone.png';
import featuredavatars from '../assets/images/hqzfeatured.png';
import bgvid from '../assets/videos/iphone.mp4';

const Apple = () => {
  return (
    <>
        <SEO title="Download HQZ iOS App" />
        <div className="position-relative vh-100 overflow-hidden">
            <video width="320" height="240" className="object-fit-cover w-100 h-100 position-absolute top-0 start-0" autoPlay loop muted playsInline>
                <source src={bgvid} type="video/mp4" />
            </video>
            <Header />
            <div className="container d-flex justify-content-center justify-content-md-end position-relative align-items-center h-100">
                <div className="mw-300">
                    <img className="w-100 mb-3" src={logo} alt="" />
                    <div className="px-3">
                        <img className="w-100 mb-3" src={featuredavatars} alt="" />
                        <p className="mb-3 text-white fw-medium">
                            Watch Exclusive Sports Shows, Series, Live Tv and Features on iPhone and Apple Tv. Download Today.
                        </p>
                        <div>
                            <a href="https://apps.apple.com/zm/app/hqz/id6445936014?platform=iphone" target="_blank" rel="noreferrer" className="btn btn-danger w-100 mb-3" aria-label="Download HQZ iOS App">Download</a>
                            <a href="https://apps.apple.com/zm/app/hqz/id6445936014?platform=iphone" target="_blank" rel="noreferrer" className="btn btn-danger w-100 mb-3" aria-label="Download HQZ iOS App">Connect</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Apple;