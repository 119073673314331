import React from 'react';
import { Link } from 'react-router-dom';
import navlogo from '../../assets/images/hqz-logo-nav.webp';

const Header = () => {
  return (
    <div className="container d-flex justify-content-between align-items-center position-relative">
        <div>
            <a className="btn btn-link" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                <i className="bi bi-list text-white fs-2"></i>
            </a>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <a href="/"><img src={navlogo} alt="HQZ" /></a>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <nav className="off-menu">
                        <ul>
                            <li><a href="https://apps.apple.com/zm/app/hqz/id6445936014?platform=iphone" target="_blank" rel="noreferrer" aria-label="Download HQZ iOS App">IPhone</a></li>
                            <li><a href="https://play.google.com/store/apps/details?id=com.hqz.app" target="_blank" rel="noreferrer" aria-label="Download HQZ Android App">Android</a></li>
                            <li><a href="https://hqztv.com/" target="_blank" rel="noreferrer">Watch Tv</a></li>
                            <li><a href="https://hqz.fan/" target="_blank" rel="noreferrer">Athletes</a></li>
                            <li><a href="https://support.hqz.gg/hc/en-us" target="_blank" rel="noreferrer">Support</a></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <Link to="/about" className="text-uppercase fw-bold text-white text-decoration-none">Details</Link>
    </div>
  );
};

export default Header;