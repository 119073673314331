import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';
import { Link } from 'react-router-dom';

const Guidelines = () => {
  return (
    <>
      <SEO title="Guidelines - HQZ" />
      <div className="position-relative min-vh-100 bg-black">
        <Header />
          <div className="container d-flex justify-content-center position-relative align-items-center h-100 py-5">
            <div className="py-5">
              <h1 className="text-white mb-5">Very <br /> Important <br /> Links</h1>
              <div>
                <div className="text-center text-white">
                  <div className="row row-cols-1 row-cols-md-2 g-5">
                    <div className="col d-flex flex-column gap-3">
                      <h3 className="text-uppercase fw-bold">Community</h3>
                      <Link to="/terms" className="fs-4 text-white text-decoration-none">Terms</Link>
                      <Link to="/privacy" className="fs-4 text-white text-decoration-none">Privacy</Link>
                      <a href="https://support.hqz.gg/hc/en-us" target="_blank" rel="noreferrer" className="fs-4 text-white text-decoration-none">Academy</a>
                    </div>
                    <div className="col d-flex flex-column gap-3">
                      <h3 className="text-uppercase fw-bold">Legal</h3>
                      <Link to="/terms" className="fs-4 text-white text-decoration-none">DMCA</Link>
                      <Link to="/childrens-privacy" className="fs-4 text-white text-decoration-none">Parents</Link>
                      <a href="https://support.hqz.gg/hc/en-us" target="_blank" rel="noreferrer" className="fs-4 text-white text-decoration-none">Community</a>
                    </div>
                    <div className="col d-flex flex-column gap-3">
                      <h3 className="text-uppercase fw-bold">Network</h3>
                      <a href="https://apps.apple.com/zm/app/hqz/id6445936014?platform=iphone" target="_blank" rel="noreferrer" className="fs-4 text-white text-decoration-none">Apple</a>
                      <a href="https://play.google.com/store/apps/details?id=com.hqz.app" target="_blank" rel="noreferrer" className="fs-4 text-white text-decoration-none">Android</a>
                      <a href="https://hqztv.com/" target="_blank" rel="noreferrer" className="fs-4 text-white text-decoration-none">Smart Tv</a>
                    </div>
                    <div className="col d-flex flex-column gap-3">
                      <h3 className="text-uppercase fw-bold">Company</h3>
                      <Link to="/about" className="fs-4 text-white text-decoration-none">About</Link>
                      <Link to="/contact-us" className="fs-4 text-white text-decoration-none">Contact</Link>
                      <a href="https://shop.hqz.gg/" target="_blank" rel="noreferrer" className="fs-4 text-white text-decoration-none">Airdrops</a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Guidelines;