import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black py-3 text-center position-relative z-3">
        <nav className="footer-nav container">
            <ul className="d-flex flex-wrap justify-content-center gap-3 mb-0">
                <li>
                    <Link to="/about">Details</Link>
                </li>
                <li>
                    <a href="https://hqz.gg" target="_blank" rel="noreferrer">Headlines</a>
                </li>
                <li>
                    <Link to="/guidelines">Rules</Link>
                </li>
                <li>
                    <Link to="/childrens-privacy">Parents</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
        </nav>
        <p>Copyright &copy; {new Date().getFullYear()} HQZ. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;