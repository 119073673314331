import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';
import logo from '../assets/images/hqz-logo.png';
import bgvid from '../assets/videos/hqz-about-2.mp4';

const About = () => {
  return (
    <>
        <SEO title="About Us - HQZ" />
        <div className="position-relative min-vh-100">
            <video width="320" height="240" className="object-fit-cover w-100 h-100 position-absolute top-0 start-0" autoPlay loop muted playsInline>
                <source src={bgvid} type="video/mp4" />
            </video>
            <Header />
            <div className="container d-flex justify-content-center position-relative align-items-center h-100 py-5">
                <div className="mw-600">
                    <img className="mw-300 mx-auto d-block mb-3" src={logo} alt="" />
                    <div className="px-3">
                        <p className="mb-3 fs-4 lh-base text-white text-justify fw-medium">
                            HQZ™ is the official interactive sports streaming network for sharing clips, videos and stories. 
                            Our mission is to showcase the next generation of emerging athletes and content creators. 
                            We were ranked the #1 Sports App by three kids we asked at In-N-Out Burger. If you love sports, 
                            come hang out with us. Create a free account to get courtside access to the culture. 
                            Don’t steal that line!
                        </p>
                        <p className="mb-3 fs-4 lh-base text-white fw-medium text-center">
                            Welcome to Headquarterz!!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  );
};

export default About;